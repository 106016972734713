<template>
    <div class="flex flex-col items-center justify-start bg-[#212533] relative w-screen h-screen overflow-hidden" data-theme="dark">
        <div class="bg-black w-full h-full absolute top-0 bottom-0 left-0 right-0 z-[0]"></div>
        <img class="w-full h-full object-cover absolute top-0 bottom-0 left-0 right-0 z-[1] opacity-[.12]" src="@/assets/img/portfolio_pages/designer-bg.jpg">
        <Transition enter-active-class="animate__animated animate__fadeInRight" appear>
            <img class="fade-img max-w-[35%] opacity-[.9] w-full h-auto object-contain absolute top-[7%] right-0 z-[2]" src="@/assets/img/portfolio_pages/designer-shape-1.png">
        </Transition>
        <Transition enter-active-class="animate__animated animate__jackInTheBox" appear>
            <p class="absolute select-none pointer-events-none top-0 font-thin text-[6vw] text-white z-[3]" style="font-family: PaulJackson;">Designer</p>
        </Transition>
        <Transition enter-active-class="animate__animated animate__fadeInDown" appear>
            <img class="max-w-[35%] opacity-[.5] w-full h-auto object-contain absolute top-0 left-0 z-[4]" src="@/assets/img/portfolio_pages/designer-shape-6.png">
        </Transition>
        <div class="max-w-[35%] w-full z-[4] absolute top-[15%] left-0 flex flex-row items-center justify-center gap-16 p-0">
            <Transition enter-active-class="animate__animated animate__backInLeft" appear>
                <div class="font-bold w-36 text-center rounded-full bg-white/50 text-[#212533] px-8 py-1 m-0">About Me</div>
            </Transition>
            <Transition enter-active-class="animate__animated animate__backInLeft animate__fast" appear>
                <div class="font-bold w-36 text-center rounded-full bg-white/50 text-[#212533] px-8 py-1 m-0">My Art</div>
            </Transition>
        </div>

        <Transition enter-active-class="animate__animated animate__fadeInLeft" appear>
            <img class="opacity-[.7] w-full max-h-[45%] h-auto object-cover absolute bottom-0 left-0 z-[4]" src="@/assets/img/portfolio_pages/designer-shape-4.png">
        </Transition>

        <div class="absolute bottom-0 max-h-[45%] h-full flex flex-col items-start justify-center gap-2 z-[5] right-[45%]">
            <Transition enter-active-class="animate__animated animate__fadeInLeft" appear>
                <p style="font-family: HelveticaNeue; line-height: 4rem;" class="text-white/80 titles inline-block font-bold">DESINGER</p>
            </Transition>
            <Transition enter-active-class="animate__animated animate__fadeInRight" appear>
                <p style="font-family: HelveticaNeue; line-height: 4rem;" class="text-white/80 titles inline-block font-bold">WORDPRESS</p>
            </Transition>
            <Transition enter-active-class="animate__animated animate__fadeInLeft" appear>
                <p style="font-family: HelveticaNeue; line-height: 4rem;" class="text-white/80 titles inline-block font-bold">DEVELOPER</p>
            </Transition>
        </div>

        <Transition enter-active-class="animate__animated animate__fadeInLeft" appear>
            <div class="max-w-[89%] w-full rounded-full h-[2px] absolute bottom-[49%] left-0 bg-gray-400 opacity-70 z-[5]"></div>
        </Transition>

        <Transition enter-active-class="animate__animated animate__fadeInUp" appear>
            <img class="max-w-[35%] opacity-[.5] w-full h-auto object-contain absolute bottom-0 left-0 z-[5]" src="@/assets/img/portfolio_pages/designer-shape-3.png">
        </Transition>
        
        <Transition enter-active-class="animate__animated animate__fadeInUp" appear>
            <div class="w-[2px] rounded-full h-[60%] absolute bottom-0 right-16 bg-gray-400 opacity-70 z-[5] flex flex-row items-start justify-center">
                <p class="text-white text-[1.1vw] -rotate-90 -ms-8 mt-28 tracking-widest z-[5] whitespace-nowrap" style="font-family: HelveticaNeue;">FATEMEH BOLOKYAN</p>
            </div>
        </Transition>

        <div class="absolute bottom-[3%] max-w-[30%] w-full right-[9.5%] z-[6]">
            <div class="relative w-full">
                <Transition enter-active-class="animate__animated animate__bounceIn" appear>
                    <img class="w-full h-auto object-contain" src="@/assets/img/portfolio_pages/designer-girl-pic.png">
                </Transition>
                <Transition enter-active-class="animate__animated animate__fadeInLeft" appear>
                    <img class="w-[40%] h-auto object-contain absolute -top-[20%] -left-[10%] z-[7]" src="@/assets/img/portfolio_pages/designer-shape-5.png">
                </Transition>
                <Transition enter-active-class="animate__animated animate__fadeInRight" appear>
                    <img class="w-[40%] h-auto object-contain absolute -bottom-[10%] -right-[15%] z-[7]" src="@/assets/img/portfolio_pages/designer-shape-5.png">
                </Transition>
            </div>
        </div>
    </div>
</template>

<script>
// import { useElementVisibility } from '@vueuse/core';

export default {
    name: 'Designer',
    components: {
    },
    data() {
        return {
        }
    },
    mounted() {
        this.awake();
    },
    methods: {
        awake() {
            this.scrollToTop();

            // if(document.getElementById('wordpress_elm'))
            //     this.wordpress_visiblity = useElementVisibility(document.getElementById('wordpress_elm'));

            // document.getElementById('wrapper').addEventListener('scroll', ()=>{
            //     if(this.wordpress_visiblity)
            //         this.$refs.wordpress_elm_ref.show_elements();
            // });
        },
        // scroll page to top
        scrollToTop() {
            if(document.getElementById('wrapper'))
                document.getElementById('wrapper').scrollTo({ top: 0, behavior: 'smooth' });
        },
    }
}
</script>

<style>
.titles {
    font-size: clamp(1rem, 4vw, 10rem);
}
.fade-img {
    /* -webkit-mask-image: -webkit-gradient(linear, top left, top right, from(rgba(0,0,0,1)), to(rgba(0,0,0,0))); */
    mask-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0));
}
</style>
