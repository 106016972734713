<template>
    <div class="md:max-w-[60%] w-full grid grid-cols-1 gap-4" style="font-family: ShabnamFD, Montserrat;">
        <div class="flex flex-row items-center justify-center gap-8 w-full">
            <div class="hidden md:flex w-full h-[2px] bg-base-200 dark:bg-base-content/30 rounded-full"></div>
            <p class="font-bold text-4xl text-base-content whitespace-nowrap pointer-events-none">تیم مارا ملاقات کنید</p>
            <div class="hidden md:flex w-full h-[2px] bg-base-200 dark:bg-base-content/30 rounded-full"></div>
        </div>
        <p class="font-bold text-2xl text-base-content/70 text-center w-full pointer-events-none">پرشور، فعال، متخصص</p>

        <div class="w-full grid grid-cols-2 md:grid-cols-4 gap-10" dir="rtl">
            <!-- person 1 -->
            <div class="w-full flex flex-col items-center justify-center gap-2">
                <div class="group w-full h-auto aspect-square rounded-full overflow-clip">
                    <img class="group-hover:scale-110 transition-all ease-in-out duration-700 w-full h-full object-cover rounded-full" src="@/assets/img/image-not-found.jpg" alt="">
                </div>
                <p class="w-full text-center font-semibold text-base-content text-xl mt-2 pointer-events-none">محمدجواد یوسفیان</p>
                <p class="w-full text-center text-base-content/70 text-md pointer-events-none">مدیریت و فروش</p>
                <div class="flex flex-row items-center justify-center gap-0">
                    <!-- instagram button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-instagram"></i>
                    </button>
                    <!-- whatsapp button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-whatsapp"></i>
                    </button>
                    <!-- telegram button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-telegram"></i>
                    </button>
                    <!-- linkedin button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-linkedin"></i>
                    </button>
                </div>
            </div>
            <!-- person 2 -->
            <div class="w-full flex flex-col items-center justify-center gap-2">
                <div class="group w-full h-auto aspect-square rounded-full overflow-clip">
                    <img class="group-hover:scale-110 transition-all ease-in-out duration-700 w-full h-full object-cover rounded-full" src="@/assets/img/image-not-found.jpg" alt="">
                </div>
                <p class="w-full text-center font-semibold text-base-content text-xl mt-2 pointer-events-none">میثم مشکات</p>
                <p class="w-full text-center text-base-content/70 text-md pointer-events-none">سرپرست بخش نرم‌افزار</p>
                <div class="flex flex-row items-center justify-center gap-0">
                    <!-- instagram button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-instagram"></i>
                    </button>
                    <!-- whatsapp button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-whatsapp"></i>
                    </button>
                    <!-- telegram button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-telegram"></i>
                    </button>
                    <!-- linkedin button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-linkedin"></i>
                    </button>
                </div>
            </div>
            <!-- person 3 -->
            <div class="w-full flex flex-col items-center justify-center gap-2">
                <div class="group w-full h-auto aspect-square rounded-full overflow-clip">
                    <img class="group-hover:scale-110 transition-all ease-in-out duration-700 w-full h-full object-cover rounded-full" src="@/assets/img/image-not-found.jpg" alt="">
                </div>
                <p class="w-full text-center font-semibold text-base-content text-xl mt-2 pointer-events-none">فاطمه بلوکیان</p>
                <p class="w-full text-center text-base-content/70 text-md pointer-events-none">سرپرست بخش گرافیک و وردپرس</p>
                <div class="flex flex-row items-center justify-center gap-0">
                    <!-- instagram button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-instagram"></i>
                    </button>
                    <!-- whatsapp button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-whatsapp"></i>
                    </button>
                    <!-- telegram button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-telegram"></i>
                    </button>
                    <!-- linkedin button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-linkedin"></i>
                    </button>
                </div>
            </div>
            <!-- person 4 -->
            <div class="w-full flex flex-col items-center justify-center gap-2">
                <div class="group w-full h-auto aspect-square rounded-full overflow-clip">
                    <img class="group-hover:scale-110 transition-all ease-in-out duration-700 w-full h-full object-cover rounded-full" src="@/assets/img/image-not-found.jpg" alt="">
                </div>
                <p class="w-full text-center font-semibold text-base-content text-xl mt-2 pointer-events-none">بهزاد</p>
                <p class="w-full text-center text-base-content/70 text-md pointer-events-none">پشتیبانی فنی</p>
                <div class="flex flex-row items-center justify-center gap-0">
                    <!-- instagram button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-instagram"></i>
                    </button>
                    <!-- whatsapp button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-whatsapp"></i>
                    </button>
                    <!-- telegram button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-telegram"></i>
                    </button>
                    <!-- linkedin button -->
                    <button class="btn btn-sm btn-ghost btn-square">
                        <i class="fa-brands fa-linkedin"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OutTeam',
}
</script>
