<template>
    <div class="w-full flex flex-row items-center justify-center dark:text-white/80" style="font-family: ShabnamFD, Montserrat;">
        <div class="md:max-w-[60%] w-full p-2 px-4 pl-2 backdrop-blur-sm bg-white/30 rounded-box flex flex-row-reverse items-center justify-between gap-2">
            <div class="flex flex-row-reverse items-center justify-center gap-2">
                <a href="/" class="cursor-pointer">
                    <img class="w-auto h-12" src="@/assets/img/logo.png" alt="">
                    <!-- <img class="w-auto h-12 hidden dark:inline-block" src="@/assets/img/logo-dark-2.png" alt=""> -->
                </a>
                <button class="hidden md:inline-block btn btn-ghost text-base" href="/">نمونه کارها</button>
                <button class="hidden md:inline-block btn btn-ghost text-base" href="/">تماس با ما</button>
                <button class="hidden md:inline-block btn btn-ghost text-base" href="/">درباره ما</button>
            </div>
            <div class="flex flex-row items-center justify-center gap-0">
                <!-- menu button -->
                <button class="md:hidden btn btn-sm btn-ghost btn-square">
                    <i class="fa-regular fa-bars"></i>
                </button>
                <!-- divider -->
                <div class="md:hidden divider divider-horizontal mx-0"></div>
                <!-- theme button -->
                <button @click="toggle_theme" class="btn btn-ghost btn-square">
                    <Transition enterActiveClass="animate__animated animate__fadeIn">
                        <i v-show="this.$store.state.app_theme == 'light'" class="fa-light fa-sun-bright text-xl"></i>
                    </Transition>
                    <Transition enterActiveClass="animate__animated animate__fadeIn">
                        <i v-show="this.$store.state.app_theme == 'dark'" class="fa-solid fa-moon text-xl"></i>
                    </Transition>
                </button>
                <!-- divider -->
                <div class="hidden md:flex divider divider-horizontal mx-0"></div>
                <!-- instagram button -->
                <button class="hidden md:inline-block btn btn-ghost btn-square">
                    <i class="fa-brands fa-instagram text-xl text-[#ee2a7b]"></i>
                </button>
                <!-- whatsapp button -->
                <button class="hidden md:inline-block btn btn-ghost btn-square">
                    <i class="fa-brands fa-whatsapp text-xl text-[#00E676]"></i>
                </button>
                <!-- telegram button -->
                <button class="hidden md:inline-block btn btn-ghost btn-square">
                    <i class="fa-brands fa-telegram text-xl text-[#24A1DE]"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    methods: {
        toggle_theme() {
            this.$store.state.app_theme = this.$store.state.app_theme == 'light' ? 'dark' : 'light';
            this.$store.state.use_dark = this.$store.state.app_theme == 'dark' ? true : false;
        }
    }
}
</script>
